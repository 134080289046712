<template>
  <div class="add-comment">
    <!-- 头部 -->
    <van-nav-bar
      title="发表评价"
      left-arrow
      @click-left="goBack"
      fixed
      right-text="发布"
      @click-right="addComment"
      class="dark_bg"
    />
    <!-- 分数列表 -->
    <ul class="score-list dark_shop_bg">
      <li
        v-for="(item, index) in scoreList"
        :key="index"
        :class="{ active: index == activeIndex }"
        @click="choiceScore(index)"
      >
        <span class="iconfont" :class="item.icon"></span>
        <p>{{ item.scoreText }}</p>
      </li>
    </ul>
    <!-- 评论输入 -->
    <section class="comment-enter dark_bg">
      <van-field
        v-model="comment"
        type="textarea"
        placeholder="对商品还有什么其他的感受吗，来聊聊吧~"
        rows="4"
        maxlength="200"
        clearable
        autosize
        class="dark_shop_bg"
      />
      <!-- <ul>
        <li>
          <span class="iconfont icon-xiangji"></span>
          <p>晒张图吧</p>
        </li>
        <li>
          <span class="iconfont icon-shipin_huabanfuben"></span>
          <p>来段视频</p>
        </li>
      </ul>-->
    </section>
    <!-- 匿名评价 -->
    <p>
      <van-cell size="large" class="dark_shop_bg">
        <van-checkbox
          v-model="checked"
          slot="icon"
          checked-color="#c22525"
          icon-size="16"
          >匿名评价</van-checkbox
        >
      </van-cell>
    </p>
  </div>
</template>

<script>
import { NavBar, Field, Cell, Checkbox } from "vant";
export default {
  components: {
    [NavBar.name]: NavBar,
    [Field.name]: Field,
    [Cell.name]: Cell,
    [Checkbox.name]: Checkbox,
  },
  data() {
    return {
      scoreList: [
        { icon: "icon-xiaolian", scoreText: "强烈推荐" },
        { icon: "icon-xiaoliansmiley40", scoreText: "很满意" },
        { icon: "icon-haoping", scoreText: "还不错" },
        { icon: "icon-yiban", scoreText: "一般般" },
        { icon: "icon-chaping", scoreText: "不满意" },
      ],
      comment: "",
      checked: true,
      activeIndex: 0,
      activeScore: 5,
      params: {
        accessToken: localStorage.getItem("token"),
        orderId: "", // 订单ID
        goodsId: "", // 待规格商品ID
        goodsHomeId: "", // 商品主ID
        commentScore: "", // 评论分数
        isAnonymity: "", // 是否匿名
        commentContent: "", // 评论内容
      },
    };
  },
  created() {
    // this.getOrderDetail();
    // this.$nextTick(() => {
    //   // this.size = document.documentElement.style.fontSize;
    //   this.size = window.getComputedStyle(document.documentElement)["fontSize"];
    //   console.log(this.size);
    //   let showsize = this.size.split("p")[0];
    //   console.log(showsize);
    //   document.documentElement.style.fontSize = showsize / 3.125 + "px";
    // });
  },

  // beforeDestroy() {
  //   document.documentElement.style.fontSize = this.size;
  // },
  methods: {
    goBack() {
      this.$router.back(0);
    },
    choiceScore(index) {
      this.activeIndex = index;
      this.activeScore = 5 - index;
    },
    addComment() {
      this.params.commentContent = this.comment;
      this.params.commentScore = this.activeScore;
      if (this.checked) {
        this.params.isAnonymity = "1";
      } else {
        this.params.isAnonymity = "0";
      }

      let row = this.$route.params.row;
      this.params.userId = localStorage.customerId;
      let skuId = "";
      let spuId = "";

      if (row.skuList.length) {
        for (let i = 0; i < row.skuList.length; i++) {
          skuId += row.skuList[i].skuId;
          spuId += row.skuList[i].spuId;
        }
      }

      let arr = {
        skuId: skuId,
        spuId: spuId,
        orderId: row.id,
        content: this.params.commentContent,
        score: this.params.commentScore,
        isAnonymity: this.params.isAnonymity,
      };
      let oid = this.params.userId;
      let newarr = {
        appraiseDTOList: [arr],
        orderId: row.id,
      };

      this.$api.my
        .addCommentAddress(
          oid,

          JSON.stringify(newarr)
        )
        .then((res) => {
          // if (res.code == 100 && res.returnValue == 1) {
          if (res.errno == 200) {
            this.$toast.success({
              message: "发布评价成功",
              duration: 1000,
              // onClose: () => {
              //   // 去到评论列表页
              //   this.$router.replace(
              //     `/goodsdetail/${this.params.goodsHomeId}/comment`
              //   );
              // },
            });
            this.$router.go(-1);
          } else {
            this.$toast(res.errmsg);
          }
        });
    },
    getOrderDetail() {
      var loginToken = localStorage.getItem("token");
      this.$server
        .orderSureAddress({
          loginToken: localStorage.getItem("token"),
          orderId: this.$route.params.orderId,
        })
        .then((res) => {
          if (res.code == 100) {
            const gindex = this.$route.params.gindex;
            this.params.orderId = this.$route.params.orderId;
            this.params.goodsId =
              res.returnValue.listGoodsOrderDetailVO[gindex].goodsId;
            this.params.goodsHomeId =
              res.returnValue.listGoodsOrderDetailVO[gindex].goodsHomeId;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.add-comment {
  width: 100%;
  box-sizing: border-box;
  padding-top: 0.96rem;
  .van-nav-bar {
    box-shadow: 0px 2px 4px #efefef;
    .van-icon {
      color: #333;
    }
    .van-nav-bar__text {
      color: #c22525;
    }
  }
  .score-list {
    width: 100%;
    display: flex;
    background-color: #fff;
    margin: 0.16rem 0;
    box-sizing: border-box;
    padding: 0.32rem 0;
    li {
      flex: 1;
      text-align: center;
      color: #999;
      font-size: 0.26rem;
      &.active {
        color: #e59a38;
      }
      span {
        font-size: 0.56rem;
      }
      p {
        line-height: 0.44rem;
      }
    }
  }
  .comment-enter {
    background-color: #fff;
    width: 100%;
    box-sizing: border-box;
    padding: 0 0.16rem;
    & > ul {
      width: 100%;
      box-sizing: border-box;
      padding: 0.24rem;
      display: flex;
      li {
        box-sizing: border-box;
        padding: 0.24rem;
        text-align: center;
        font-size: 0.21rem;
        border: 1px dashed #ccc;
        color: #ccc;
        margin-right: 0.24rem;
        span {
          font-size: 0.64rem;
        }
      }
    }
  }
  & > p {
    margin: 0.16rem 0;
  }
}
</style>